import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import SideDrawer from "../../components/design-system/SideDrawer";
import MyPageEdit from "./card/MyPageEdit";
import MyBankEdit from "./card/MyBankEdit";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import GoogleAd from "../../components/ads/GoogleAd";

export default function MyPage() {
  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[40px] mx-[200px] w-full max-w-[1280px] relative">
          <div className=" animate-bounce gap-1">
            <Text
              fontSize={14}
              fontWeight={600}
              color="black"
              className="dark:text-darkText"
            >
              메뉴
            </Text>
            <img src="/img/standbuy/icon/scroll-down.svg" alt="" />
          </div>
          <div className="flex items-center mb-[30px]">
            <SideDrawer />
            <Text className="title dark:text-darkText">내 정보 수정</Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <MyPageEdit />
          {/*<MyBankEdit />*/}
        </div>
      </motion.div>

      <div className="w-full sm:max-w-[728px] mx-auto my-5">
        <GoogleAd />
      </div>

      <CustomFooter styles={"fixed bottom-0"} />
    </div>
  );
}
