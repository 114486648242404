import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  order: "DEFAULT",
};

export const orderSlice = createSlice({
  name: "order",
  initialState: initialToastState,
  reducers: {
    setOrderFetched: (state, action) => {
      state.order = action.payload;
    },
  },
});

export const { setOrderFetched } = orderSlice.actions;

export default orderSlice.reducer;
