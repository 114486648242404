//@ts-nocheck
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { instance } from "../../../api";
import { API_DEFAULT } from "../../../api/api";
import { useMediaQuery } from "react-responsive";
import Text from "../../../components/design-system/Text";
import StockListSide from "../../feed/stock-list/StockListSide";
import { shallowEqual } from "react-redux";
import { setLocationList } from "../../../store/reducer/locationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { FadeLoader } from "react-spinners";

export default function LocationSideView({
  loading,
  setLoading,
  fetchLocations,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data);
  const bounds = useAppSelector((state) => state.bounds);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const groupKeyRef = useRef(0); // groupKey를 useRef로 관리
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<any[]>([]);
  const isRefreshed = useRef(false); // 새로고침 여부를 추적하는 플래그
  const [gridKey, setGridKey] = useState(0); // Grid 리렌더링을 위한 키
  const [spinnersFlag, setSpinnersFlag] = useState<any>(true);

  // 새로고침 버튼 클릭 시 호출되는 함수
  const fetchItemsNow = async () => {
    groupKeyRef.current = 0; // groupKey 초기화
    setItems([]); // 아이템 초기화
    isRefreshed.current = true; // 새로고침이 되었음을 표시
    setGridKey((prevKey) => prevKey + 1); // Grid를 리렌더링하여 데이터 요청 트리거
    fetchLocations();
  };

  // 아이템을 가져오는 함수 (새로고침 이후 사용)
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;

    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: bounds.sw.lat,
      latMax: bounds.ne.lat,
      lonMin: bounds.sw.lng,
      lonMax: bounds.ne.lng,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  // 초기 렌더링 시 사용하는 아이템 가져오는 함수 (lat/lon 값 고정)
  async function getItemMount(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;

    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: 36.507468472827576,
      latMax: 38.60617845599798,
      lonMin: 125.24388600000003,
      lonMax: 128.75951100000003,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  return (
    <div className={`${isMobile ? "mt-[20px]" : "mt-[80px]"}`}>
      {/* 새로고침 버튼 */}
      {/* <button onClick={fetchItemsNow} className="refresh-button">
        새로고침
      </button> */}

      <JustifiedInfiniteGrid
        className={"container"}
        key={gridKey} // Grid 리렌더링을 위한 키
        placeholder={
          <div className="placeholder">추가 데이터를 불러오는 중...</div>
        }
        columnRange={[0, 1]}
        threshold={3000}
        gap={8}
        loading={
          spinnersFlag ? (
            <FadeLoader
              color={
                themeData === "light" || themeData === ""
                  ? "#121212"
                  : "#ffffff"
              }
            />
          ) : undefined
        }
        onRequestAppend={(e) => {
          const nextGroupKey = groupKeyRef.current + 1;
          groupKeyRef.current = nextGroupKey; // groupKey 즉시 업데이트

          e.wait();

          setTimeout(async () => {
            let add;
            if (isRefreshed.current) {
              // 새로고침 이후에는 getItems 사용
              add = await getItems(nextGroupKey, 20);
            } else {
              // 초기 렌더링 시에는 getItemMount 사용
              add = await getItemMount(nextGroupKey, 20);
            }

            if (add?.length === 0) {
              return;
            }

            dispatch(setLocationList({ data: add, reset: false }));
            setItems((prevItems) => [...prevItems, ...add]);
            e.ready();
          }, 1);
        }}
      >
        {items?.map((item: any, index: any) => (
          <StockListSide
            data-grid-groupkey={item.groupKey}
            key={index}
            index={index}
            item={item}
          />
        ))}
      </JustifiedInfiniteGrid>

      {!loading && (
        <button
          onClick={fetchItemsNow}
          style={{ background: "rgba(0, 74, 111, 0.8)" }}
          className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(0,74,111,0.8)]  p-4 text-white text-center z-50 rounded-3xl font-bold"
        >
          <FontAwesomeIcon icon={faRotateRight} className="mr-2" /> 현 지도에서
          검색
        </button>
      )}
    </div>
  );
}
