import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const PaginationContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props: any) => (props.isMobile ? "" : "120px")};
  margin-bottom: 10px;
`;

const PaginationButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  color: #b5b5b5;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  background-color: inherit;

  &:hover {
    background-color: #e2e6ea;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.active {
    color: #535353;
    font-weight: bold;
  }
`;

const PaginationButtonArrow = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background-color: inherit;
  color: #b5b5b5;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: #e2e6ea;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

// 페이지 번호 목록을 생성하는 함수
function getPageNumbers(
  currentPage: number,
  totalPages: number,
  maxVisible: number = 10
) {
  // totalPages 가 maxVisible 이하라면 모두 표시
  if (totalPages <= maxVisible) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const pages: (number | string)[] = [];
  const sideWidth = 2; // 현재 페이지 양 옆으로 몇 개의 페이지를 보여줄지

  const left = currentPage - sideWidth;
  const right = currentPage + sideWidth;

  // 항상 첫 페이지, 마지막 페이지는 보여주도록
  const firstPage = 1;
  const lastPage = totalPages;

  // range 배열에 표시할 페이지를 먼저 담는다.
  const range: number[] = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i === firstPage || i === lastPage || (i >= left && i <= right)) {
      range.push(i);
    }
  }

  // range 배열을 이용해 사이에 "..." 추가
  let l: number | undefined;
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        // 사이에 한 페이지만 비었을 경우 바로 그 페이지를 넣는다.
        pages.push(l + 1);
      } else if (i - l !== 1) {
        // 그 외엔 "..." 표시
        pages.push("...");
      }
    }
    pages.push(i);
    l = i;
  }

  return pages;
}

export function Pagination({
  postsNum,
  postsPerPage,
  setCurrentPage,
  currentPage,
}: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const totalPages = Math.ceil(postsNum / postsPerPage);

  if (totalPages === 1) {
    return null;
  }

  // 페이지 목록 생성
  const pageList = getPageNumbers(currentPage, totalPages, 10); // 10개를 기준으로 예시

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <PaginationContainer isMobile={isMobile}>
      <PaginationButtonArrow
        onClick={goToPrevPage}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </PaginationButtonArrow>

      {pageList?.map((page, index) => {
        if (page === "...") {
          return (
            <PaginationButton key={index} disabled>
              ...
            </PaginationButton>
          );
        }
        return (
          <PaginationButton
            key={index}
            onClick={() => setCurrentPage(page)}
            className={currentPage === page ? "active" : ""}
          >
            {page}
          </PaginationButton>
        );
      })}

      <PaginationButtonArrow
        onClick={goToNextPage}
        disabled={currentPage === totalPages}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </PaginationButtonArrow>
    </PaginationContainer>
  );
}
