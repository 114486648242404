import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { URL_HOST } from "../../../api/api";

export default function StockListSide({ item, index, onClick }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const [endModal, setEndModal] = useState({
    show: false,
    data: "",
  });

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url, options) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <div
      className={`w-full h-auto py-4 px-2 ${index % 2 === 1 ? "bg-gray-100" : ""} flex  ${isMobile ? "gap-4" : "flex-col"} md:flex-row items-center cursor-pointer`}
      onClick={() => {
        window.name = JSON.stringify({
          contentId: item?.contentId,
        });
        window.open(
          `${URL_HOST}feed-info`,
          "feed",
          `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
        );
      }}
    >
      <img
        src={item?.thumbnailPath}
        alt={item?.contentName}
        className={`w-16 h-16 md:w-24 md:h-24 rounded-lg object-cover cursor-pointer ${!isMobile && "mb-4"} md:mb-0 md:mr-6`}
      />

      <div className="flex-1">
        <Text
          fontSize={isMobile ? 14 : 16}
          fontWeight={700}
          color={"#134B70"}
          className="truncate"
        >
          {item?.contentName}
        </Text>

        <hr className="my-1 border-gray-300" />

        <Text
          fontSize={isMobile ? 12 : 14}
          fontWeight={700}
          color={"#b2b2b2"}
          className="truncate"
        >
          {item?.classification === "IMAGE" ? "IMAGE" : "VIDEO"}
        </Text>

        <Text
          fontSize={isMobile ? 12 : 14}
          fontWeight={600}
          color={"#b2b2b2"}
          className="truncate"
        >
          {item?.memberNickname}
        </Text>
      </div>
    </div>
  );
}
