import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./store/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import LoginPage from "./pages/login/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import LoginEmailPage from "./pages/loginEmail/LoginEmailPage";
import JoinPage from "./pages/join/JoinPage";
import JoinFormPage from "./pages/join/JoinFormPage";
import Health from "./pages/etc/Health";
import Ready from "./pages/etc/Ready";
import ServerError from "./pages/etc/ServerError";
import AuthContextProvider from "./util/context/AuthContextProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UploadPage from "./pages/stock-upload/UploadPage";
import UploadEnd from "./pages/stock-upload/UploadEnd";
import ProfileEditPage from "./pages/profileEdit/ProfileEditPage";
import AuthContextFalseProvider from "./util/context/AuthContextFalseProvider";
import UploadSccuess from "./pages/stock-upload/UploadSccuess";
import LandingPage from "./pages/landing/LandingPage";
import CartPage from "./pages/cart/CartPage";
import PayPage from "./pages/pay/PayPage";
import BillPage from "./pages/pay/bill/BillPage";
import BillSuccess from "./pages/pay/bill/BillSuccess";
import LockerPage from "./pages/locker/LockerPage";
import SharePage from "./pages/share/SharePage";
import LoginErrorPage from "./pages/loginError/LoginErrorPage";
import SalesPage from "./pages/sales/SalesPage";
import StudioPage from "./pages/studio/StudioPage";
import OthersStudioPage from "./pages/studio/OthersStudioPage";
import NoticePage from "./pages/notice/NoticePage";
import NoticeDetail from "./pages/notice/NoticeDetail";
import MySubsPage from "./pages/mySubs/MySubsPage";
import PayListPage from "./pages/payList/PayListPage";
import FofPage from "./pages/fof/FofPage";
import MyPage from "./pages/myPage/MyPage";
import FofCreditPage from "./pages/fof-credit/FofCreditPage";
import ExchangePage from "./pages/exchange/ExchangePage";
import ExchangeDetailPage from "./pages/exchangeDetail/ExchangeDetailPage";
import SearchFeedPage from "./pages/searchFeed/SearchFeedPage";
import BillFail from "./pages/pay/bill/BillFail";
import LocationFeedPage from "./pages/locationFeed/LocationFeedPage";
import PasswordFindPage from "./pages/passwordFind/PasswordFindPage";
import LocationFeedSearch from "./pages/locationFeed/LocationFeedSearch";
import NiceAuthPage from "./pages/niceAuth/NiceAuthPage";
import UploadDetail from "./pages/stock-upload/UploadDetail";
import TermsPage from "./pages/terms/TermsPage";
import NiceResultPage from "./pages/niceAuth/NiceResultPage";
import SocialTermsPage from "./pages/socialTerms/SocialTermsPage";
import PolicyPage from "./pages/policy/PolicyPage";
import ResetAuthPage from "./pages/resetAuth/ResetAuthPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import CertificationPage from "./pages/certificationtsx/CertificationPage";
import DenyPage from "./pages/deny/DenyPage";
import NewFeedPage from "./pages/feed/NewFeedPage";
import FeedFilterPage from "./pages/feedFilter/FeedFilterPage";
import LocationTermsPage from "./pages/locationTerms/LocationTermsPags";
import MobileFeedPage from "./pages/mobileFeed/MobileFeedPage";
import MobileContextProvider from "./util/context/MobileContextProvider";
import MobileEndPage from "./components/layout/MobileEndPage";
import LoginEndPage from "./pages/login/LoginEndPage";
import AlarmPage from "./pages/alarm/AlarmPage";
import NiceAuthFailPage from "./pages/niceAuth/NiceAuthFailPage";
import FeedInfoPage from "./pages/feed/FeedInfoPage";
import EmailLoginDenyPage from "./pages/deny/EmailLoginDenyPage";

const persistor = persistStore(store);
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      // {
      //   index: true,
      //   element: <Navigate to="/landing" replace={true} />,
      // },
      // {
      //   path: "/landing",
      //   element: <LandingPage />,
      // },
      // },
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "/feed",
        element: (
          // <AuthContextProvider>
          <NewFeedPage />
          // </AuthContextProvider>
        ),
      },
      {
        path: "/feed-info",
        element: <FeedInfoPage />,
      },
      {
        path: "/share/:contentId",
        element: <SharePage />,
      },
      {
        path: "/mobile-feed/:contentId",
        element: <MobileFeedPage />,
      },
      {
        path: "/login",
        element: (
          <AuthContextFalseProvider>
            <LoginPage />
          </AuthContextFalseProvider>
        ),
      },
      {
        path: "/login-email",
        element: (
          <AuthContextFalseProvider>
            <LoginEmailPage />
          </AuthContextFalseProvider>
        ),
      },
      {
        path: "/join",
        element: (
          <AuthContextFalseProvider>
            <JoinPage />
          </AuthContextFalseProvider>
        ),
      },
      {
        path: "/join-form",
        element: (
          <AuthContextFalseProvider>
            <JoinFormPage />
          </AuthContextFalseProvider>
        ),
      },
      {
        path: "/profile-edit",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <ProfileEditPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/upload",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <UploadPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/upload-detail",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <UploadDetail />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/upload-end",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <UploadEnd />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/upload-sccuess",
        element: (
          <AuthContextProvider>
            <UploadSccuess />
          </AuthContextProvider>
        ),
      },
      {
        path: "/cart",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <CartPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      // {
      //   path: "/pay",
      //   element: (
      //     <AuthContextProvider>
      //       <PayPage />
      //     </AuthContextProvider>
      //   ),
      // },
      // {
      //   path: "/pay/bill",
      //   element: (
      //     <AuthContextProvider>
      //       <BillPage />
      //     </AuthContextProvider>
      //   ),
      // },
      {
        path: "/pay/bill/:orderId/success",
        element: <BillSuccess />,
      },

      {
        path: "/pay/bill/fail",
        element: <BillFail />,
      },
      {
        path: "/locker",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <LockerPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/sales",
        element: (
          <AuthContextProvider>
            <SalesPage />
          </AuthContextProvider>
        ),
      },
      {
        path: "/my-studio/:memberId",
        element: (
          // <AuthContextProvider>
          // <MobileContextProvider>
          <StudioPage />
          // </MobileContextProvider>
          // </AuthContextProvider>
        ),
      },
      {
        path: "/studio/:memberId",
        element: (
          // <AuthContextProvider>
          // <MobileContextProvider>
          <OthersStudioPage />
          // </MobileContextProvider>
          // </AuthContextProvider>
        ),
      },
      {
        path: "/notice",
        element: (
          // <AuthContextProvider>
          <NoticePage />
          // </AuthContextProvider>
        ),
      },
      {
        path: "/notice/:id",
        element: (
          // <AuthContextProvider>
          <NoticeDetail />
          // </AuthContextProvider>
        ),
      },
      {
        path: "/my-subs",
        element: (
          <AuthContextProvider>
            <MySubsPage />
          </AuthContextProvider>
        ),
      },
      // {
      //   path: "/pay-list",
      //   element: (
      //     <AuthContextProvider>
      //       <PayListPage />
      //     </AuthContextProvider>
      //   ),
      // },
      {
        path: "/fof-list",
        element: (
          <AuthContextProvider>
            <PayListPage />
          </AuthContextProvider>
        ),
      },
      {
        path: "/my-page",
        element: (
          <AuthContextProvider>
            <MobileContextProvider>
              <MyPage />
            </MobileContextProvider>
          </AuthContextProvider>
        ),
      },
      {
        path: "/fof-credit",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <FofCreditPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/exchange",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <ExchangePage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/exchange-detail",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <ExchangeDetailPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/feed/:value",
        element: <SearchFeedPage />,
      },
      {
        path: "/feed/filter",
        element: <FeedFilterPage />,
      },
      {
        path: "/location-feed",
        element: <LocationFeedPage />,
      },

      {
        path: "/location-feed/:value",
        element: <LocationFeedSearch />,
      },
      {
        path: "/password-find",
        element: <PasswordFindPage />,
      },
      {
        path: "/nice-auth",
        element: (
          <AuthContextProvider>
            <NiceAuthPage />
          </AuthContextProvider>
        ),
      },
      {
        path: "/nice-auth/result",
        element: <NiceResultPage />,
      },
      {
        path: "/nice-auth/fail",
        element: <NiceAuthFailPage />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "/location-terms",
        element: <LocationTermsPage />,
      },
      {
        path: "/social-check",
        element: <SocialTermsPage />,
      },
      {
        path: "/policy",
        element: <PolicyPage />,
      },
      {
        path: "/reset-auth",
        element: <ResetAuthPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "/cert",
        element: (
          <AuthContextProvider>
            {/*<MobileContextProvider>*/}
            <CertificationPage />
            {/*</MobileContextProvider>*/}
          </AuthContextProvider>
        ),
      },
      {
        path: "/login-deny",
        element: <DenyPage />,
      },
      {
        path: "/login-deny-email",
        element: <EmailLoginDenyPage />,
      },
      {
        path: "/mobile-login-end",
        element: <MobileEndPage />,
      },
      {
        path: "/login-end",
        element: <LoginEndPage />,
      },
      {
        path: "/alarm",
        element: <AlarmPage />,
      },
      { path: "/login-error", element: <LoginErrorPage /> },
      { path: "/health", element: <Health /> },
      { path: "/ready", element: <Ready /> },
      { path: "/server-error", element: <ServerError /> },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </>
);
