import React, { useState } from "react";
import PageCard from "../../components/design-system/PageCard";
import EditAvatarForm from "./EditAvatarForm";
import RemoveUserModal from "./modal/RemoveUserModal";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import GoogleAdsense from "react-adsense-google";
import GoogleAd from "../../components/ads/GoogleAd";

export default function ProfileEditPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [userRemove, setUserRemove] = useState({ show: false });
  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <PageCard
          title="프로필 편집"
          textButton="탈퇴하기"
          onClick={() => setUserRemove({ show: true })}
        >
          <EditAvatarForm />
          <div className={"mt-10"}>
            <GoogleAd />
          </div>
        </PageCard>
        {userRemove.show && (
          <RemoveUserModal
            open={userRemove.show}
            onClose={() => setUserRemove({ show: false })}
          />
        )}
      </div>
      {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />}
    </>
  );
}
