import React, { useState } from "react";
import Switcher from "../../components/common/Switcher";
import NiceFormCard from "./card/NIceFormCard";
import NiceSocialCard from "./card/NiceSocialCard";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../store/store";

export default function NiceAuthPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { versionData } = useAppSelector((state) => state.mobile);

  return (
    <div
      className={`bg-gray-200 dark:bg-black flex justify-center items-center h-screen`}
    >
      <Switcher className="hidden" />
      <div className="w-full flex flex-col items-center justify-center mx-5 my-10 gap-10 px-3">
        {/*<img*/}
        {/*  src={*/}
        {/*    themeData === "light" || themeData === ""*/}
        {/*      ? "/img/standbuy/logo/logo.svg"*/}
        {/*      : "/img/standbuy/logo/logo-white.svg"*/}
        {/*  }*/}
        {/*  alt="standbuy"*/}
        {/*  onClick={() => navigate(-1)}*/}
        {/*  className="cursor-pointer"*/}
        {/*  width={200}*/}
        {/*/>*/}

        {!versionData ? <NiceFormCard /> : null}
        {versionData ? <NiceSocialCard /> : null}
      </div>
    </div>
  );
}
