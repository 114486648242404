import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  data: "",
  toggle: true,
  order: "DEFAULT",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialToastState,
  reducers: {
    themeFetched: (state, action) => {
      state.data = action.payload;
    },
    setToggleFetched: (state, action) => {
      state.toggle = action.payload;
    },
    setOrderFetched: (state, action) => {
      state.order = action.payload;
    },
  },
});

export const { themeFetched, setToggleFetched, setOrderFetched } =
  themeSlice.actions;

export default themeSlice.reducer;
