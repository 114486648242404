import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { instance } from "../../../api";
import { API_DEFAULT, URL_HOST } from "../../../api/api";
import { useMediaQuery } from "react-responsive";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FadeLoader from "react-spinners/FadeLoader";
import LocationListModal from "../../../components/design-system/modal/LocationListModal";

export default function LocationDuplicateModal({
  open,
  onClose,
  listData,
}: any) {
  const { me } = useContext();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);

  const getLocationListData = async (page: number) => {
    try {
      const res = await instance.get(
        `${API_DEFAULT}/contents/search/same-location`,
        {
          params: {
            lat: listData?.lat,
            lon: listData?.lng,
            page: page,
            size: 10,
          },
        }
      );
      return Array.isArray(res.data.data) ? res.data.data : [];
    } catch (error) {
      console.error("API 호출 에러:", error);
      throw error;
    }
  };

  const { data, status } = useQuery({
    queryKey: ["location-list", currentPage, listData?.lat, listData?.lng],
    queryFn: () => getLocationListData(currentPage),
    placeholderData: keepPreviousData,
    gcTime: 5 * 60 * 1000, // 5분
    staleTime: 60 * 1000, // 1분
  });

  const isLastPage = data?.length < 10;

  const prefetchNextPosts = async (nextPage: number) => {
    await queryClient.prefetchQuery({
      queryKey: ["location-list", nextPage, listData?.lat, listData?.lng],
      queryFn: () => getLocationListData(nextPage),
    });
  };

  useEffect(() => {
    if (data && !isLastPage) {
      const nextPage = currentPage + 1;
      prefetchNextPosts(nextPage);
    }
  }, [currentPage, data, isLastPage, listData, queryClient]);

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <LocationListModal
      open={open}
      onClose={onClose}
      noOutSideClosed={false}
      height="620px"
      title="이 위치의 콘텐츠"
    >
      {status !== "success" ? (
        <FadeLoader color={"#121212"} />
      ) : data && data.length > 0 ? (
        data.map((item: any, index: number) => (
          <div
            key={item?.contentId}
            className={`w-full h-auto py-4 px-2 ${
              index % 2 === 1 ? "" : ""
            } flex  ${
              isMobile ? "gap-4" : "flex-col"
            } md:flex-row items-center cursor-pointer`}
            onClick={() => {
              window.name = JSON.stringify({
                contentId: item?.contentId,
              });
              window.open(
                `${URL_HOST}feed-info`,
                "feed",
                `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
              );
            }}
          >
            <img
              src={item?.thumbnailPath}
              alt={item?.contentName}
              className={`w-16 h-16 md:w-24 md:h-24 rounded-lg object-cover cursor-pointer ${
                !isMobile && "mb-4"
              } md:mb-0 md:mr-6`}
            />

            <div className="flex-1">
              <Text
                fontSize={isMobile ? 14 : 16}
                fontWeight={700}
                color={"#134B70"}
                className="truncate"
              >
                {item?.contentName}
              </Text>

              <hr className="my-1 border-gray-300" />

              <Text
                fontSize={isMobile ? 12 : 14}
                fontWeight={700}
                color={"#b2b2b2"}
                className="truncate"
              >
                {item?.classification === "IMAGE" ? "IMAGE" : "VIDEO"}
              </Text>

              <Text
                fontSize={isMobile ? 12 : 14}
                fontWeight={600}
                color={"#b2b2b2"}
                className="truncate"
              >
                {item?.memberNickname}
              </Text>
            </div>
          </div>
        ))
      ) : (
        <Text>데이터가 없습니다.</Text>
      )}

      <div className="flex justify-center items-center mt-4">
        <button
          className="p-2 mx-2"
          disabled={currentPage <= 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <Text fontSize={20} fontWeight={600} className="mx-4">
          {currentPage}
        </Text>

        <button
          className="p-2 mx-2"
          disabled={isLastPage}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </LocationListModal>
  );
}
