//@ts-nocheck
import React, { useEffect } from "react";
import { Chip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function PriceChip({ state, chips, setChips, setGroupKey }: any) {
  const navigate = useNavigate();
  const searchString = sessionStorage.getItem("search");

  const keywordsString = sessionStorage.getItem("keywords");
  const keywordsArray = JSON.parse(keywordsString || "[]");

  const priceString: any = sessionStorage.getItem("price");
  const priceArray: any[] = JSON.parse(priceString || "[]");

  const handleDelete = () => {
    setChips([]); // 칩을 초기화합니다.

    sessionStorage.removeItem("price");

    navigate(`/feed/filter`, {
      state: {
        keyword: keywordsArray,
        search: searchString,
        price: "",
      },
      replace: true,
    });
  };

  useEffect(() => {
    // chips와 state가 다를 때만 업데이트
    if (JSON.stringify(chips) !== JSON.stringify(state)) {
      setChips(state);
    }
  }, [state]);

  return (
    <>
      {priceArray?.length === 2 && (
        <Chip
          key="price-chip"
          value={`가격 : ${priceArray[0]} ~ ${priceArray[1]}`}
          onClose={handleDelete}
        />
      )}
    </>
  );
}
