import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useAxios } from "../../lib/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import NoSubsData from "./card/NoSubsData";
import MySubsList from "./card/MySubsList";
import { mySubsList } from "../../api/mySubs/mySubs";
import GoogleAd from "../../components/ads/GoogleAd";

export default function MySubsPage() {
  const { get } = useAxios();

  const { status, data } = useQuery({
    queryKey: ["mySubs"],
    queryFn: mySubsList,
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">구독 목록</Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          {data?.data.length < 1 ? (
            <NoSubsData />
          ) : (
            <MySubsList data={data?.data} />
          )}
        </div>
      </motion.div>

      <div className="w-full sm:max-w-[728px] mx-auto my-5">
        <GoogleAd />
      </div>

      <CustomFooter styles={"fixed bottom-0"} />
    </div>
  );
}
