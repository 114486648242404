import { Autocomplete, TextField, Chip } from "@mui/material";
// import { Chip as MaterialChip } from "@material-tailwind/react"; // 사용 중단
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { useEffect, useState } from "react";

export const InputTextField = styled(TextField)<{ themeData: string }>`
  & label {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-focused {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-error {
    color: #d32f2f;
  }
  & .MuiOutlinedInput-root {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
    & fieldset {
      border-color: ${({ themeData }) =>
        themeData === "dark" ? "white" : "#363636"};
    }
  }
  & .MuiInputBase-input {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ themeData }) =>
      themeData === "dark" ? "white" : "#363636"};
  }
`;

export default function UploadAutoInput({
  value,
  setValue,
  readOnly = false,
  switchAi,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const [inputValue, setInputValue] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  useEffect(() => {
    if (switchAi) {
      const lowercasedValue = value.map((keyword: string) =>
        keyword.toLowerCase().trim()
      );
      if (!lowercasedValue.includes("ai")) {
        setValue((prev: any) => [...prev, "AI"]);
      }
    } else {
      const filteredValue = value.filter(
        (keyword: string) => keyword.toLowerCase().trim() !== "ai"
      );
      if (filteredValue.length !== value.length) {
        setValue(filteredValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchAi]);

  const handleDelete = (index: number) => {
    setValue((prev: any[]) => prev.filter((_, i) => i !== index));
  };

  return (
    <Autocomplete
      readOnly={readOnly}
      multiple
      options={[]}
      defaultValue={[]}
      freeSolo
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          gap: "8px", // 원하는 gap 값으로 조정하세요
          padding: "4px 0",
        },
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== inputValue) {
          const cleanedValue = newInputValue.replace("#", "");

          if (!isComposing && cleanedValue.includes(",")) {
            const newKeywords = cleanedValue
              .split(",")
              .map((keyword) => keyword.trim())
              .filter((keyword) => keyword !== "");

            setValue((prev: any) => {
              const uniqueKeywords = newKeywords.filter(
                (keyword) => !prev.includes(keyword)
              );
              return [...prev, ...uniqueKeywords];
            });
            setInputValue("");
          } else {
            setInputValue(cleanedValue);
          }
        }
      }}
      onCompositionStart={() => {
        setIsComposing(true);
      }}
      onCompositionEnd={(event) => {
        setIsComposing(false);

        if (inputValue.includes(",")) {
          const newKeywords = inputValue
            .split(",")
            .map((keyword) => keyword.trim())
            .filter((keyword) => keyword !== "");

          setValue((prev: any) => {
            const uniqueKeywords = newKeywords.filter(
              (keyword) => !prev.includes(keyword)
            );
            return [...prev, ...uniqueKeywords];
          });
          setInputValue("");
        }
      }}
      onKeyDown={(event) => {
        if (isComposing) return;

        if (event.key === "Enter" && inputValue.trim() !== "") {
          const newKeywords = inputValue
            .split(",")
            .map((keyword) => keyword.trim())
            .filter((keyword) => keyword !== "");

          setValue((prev: any) => {
            const uniqueKeywords = newKeywords.filter(
              (keyword) => !prev.includes(keyword)
            );
            return [...prev, ...uniqueKeywords];
          });
          setInputValue("");
          event.preventDefault();
        }
      }}
      value={value || []}
      renderTags={(value, getTagProps) =>
        value.map((option: string, index: number) => {
          const props = getTagProps({ index });
          return (
            <Chip
              variant="filled"
              label={option}
              {...props}
              onDelete={() => handleDelete(index)}
              className="rounded-full my-2 mr-2"
              style={{ textTransform: "none" }}
            />
          );
        })
      }
      renderInput={(params) => (
        <InputTextField
          themeData={themeData}
          {...params}
          label="키워드(쉼표나 엔터를 눌러주세요!)"
          variant="standard"
          placeholder="한단어씩 쉼표 또는 엔터로 적용해요"
          sx={{
            borderBottom:
              themeData === "dark" && inputValue?.length <= 4
                ? "1px solid transparent"
                : "1px solid white",
          }}
          error={value?.length < 5}
          helperText={
            value?.length < 5 ? "최소 5개의 키워드를 입력해야 합니다." : ""
          }
        />
      )}
    />
  );
}
