//@ts-nocheck
import React, { useEffect, useRef, useState, useCallback } from "react";
import Text from "../../components/design-system/Text";
import { motion } from "framer-motion";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import LocationFeedMap from "./card/LocationFeedMap";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { useAxios } from "../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import {
  setLocationListKeywordList,
  setPositions,
  setSearchResult,
} from "../../store/reducer/locationSlice";
import { FeedNav } from "../../components/design-system/FeedNav";
import StockListCard from "../feed/stock-list/StockListCard";
import { useContext } from "../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";
import debounce from "lodash/debounce";
import { boundsFetched } from "../../store/reducer/mapBoundSlice";
import { isEqual } from "lodash";
import AlertModal from "./modal/AlertModal";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import LocationSideView from "./card/LocationSideView";
import LocationMobileList from "./card/LocationMobileList";
import LocationSideViewSearch from "./card/LocationSideViewSearch";

export default function LocationFeedSearch({ lat, lng }: any) {
  const { me } = useContext();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const bounds = useAppSelector((state) => state.bounds, shallowEqual);
  const location = useLocation();
  const navigate = useNavigate();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { positions } = useAppSelector((state) => state.location);
  const { state } = useContext();
  const [keywordId, setKeywordId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [items, setItems] = useState<any>([]);
  const { locationKeywordList } = useAppSelector(
    (state) => state.location,
    shallowEqual
  );
  const zoom = useAppSelector((state) => state.bounds.searchZoom);
  const hasFetchedOnce = useRef(false);

  const debouncedOnAllLocation = useRef(
    debounce(async ({ latMin, latMax, lonMin, lonMax, zoom }: any) => {
      setLoading(true);
      const payload = {
        latMin: Math.min(latMax, latMin),
        latMax: Math.max(latMax, latMin),
        lonMin: Math.min(lonMax, lonMin),
        lonMax: Math.max(lonMax, lonMin),
        zoom: zoom,
      };

      const res = await instance.post(
        `${API_DEFAULT}/contents/search/markers`,
        payload
      );
      if (res?.data.success) {
        dispatch(setSearchResult([]));
        dispatch(setPositions(res?.data.data));
        setLoading(false);
        dispatch(
          boundsFetched({
            ne: { lat: latMax, lng: lonMax },
            sw: { lat: latMin, lng: lonMin },
          })
        );
      } else {
        // 에러 처리
      }
    }, 500)
  ).current;

  useEffect(() => {
    // 데이터가 실제로 로드되었는지 판단하는 조건
    // bounds가 실제 좌표를 가지고 있는지(0이 아닌지) 체크
    const isDataLoaded =
      bounds.ne &&
      bounds.sw &&
      bounds.ne.lat !== 0 &&
      bounds.ne.lng !== 0 &&
      bounds.sw.lat !== 0 &&
      bounds.sw.lng !== 0;

    if (!hasFetchedOnce.current && isDataLoaded) {
      hasFetchedOnce.current = true;

      setTimeout(() => {
        debouncedOnAllLocation({
          latMin: bounds.ne.lat,
          latMax: bounds.sw.lat,
          lonMin: bounds.ne.lng,
          lonMax: bounds.sw.lng,
          zoom: zoom,
        });
      }, 500);
    }
  }, [bounds, location.pathname, zoom, debouncedOnAllLocation]);

  const fetchLocations = useCallback(() => {
    if (
      bounds.ne &&
      bounds.sw &&
      bounds.ne.lat !== 0 &&
      bounds.ne.lng !== 0 &&
      bounds.sw.lat !== 0 &&
      bounds.sw.lng !== 0
    ) {
      debouncedOnAllLocation({
        latMin: bounds.ne.lat,
        latMax: bounds.sw.lat,
        lonMin: bounds.ne.lng,
        lonMax: bounds.sw.lng,
        zoom: zoom,
      });
    }
  }, [bounds]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`w-full flex max-w-screen`}>
      {!isMobile && (
        <div className="w-1/6 relative">
          <LocationSideViewSearch
            loading={loading}
            setLoading={setLoading}
            fetchLocations={fetchLocations}
          />
        </div>
      )}

      <div
        className={`flex flex-col justify-between pt-[40px] ${
          isMobile ? "" : "fixed"
        }`}
        style={{
          left: isMobile ? "" : "calc(16.6667% - 40px)",
          width: "100%",
        }}
      >
        <motion.div
          className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className={`${isMobile ? "" : "w-full mx-10 mt-[39px]"}`}>
            <div style={{ position: "relative" }} className={"w-full"}>
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                  }}
                >
                  <FadeLoader color={"#fff"} />
                </div>
              )}
              <LocationFeedMap lat={state?.data.lat} lng={state?.data.lng} />
            </div>

            {isMobile && (
              <LocationSideViewSearch
                loading={loading}
                setLoading={setLoading}
                fetchLocations={fetchLocations}
              />
            )}
          </div>

          <div
            className={`fixed bottom-10 ${
              isMobile ? "right-10" : "right-10"
            } z-[9999]`}
          >
            <div className="flex flex-col justify-center items-center gap-4">
              {/* <FeedNav /> */}

              {isMobile && (
                <IconButton
                  //@ts-ignore
                  className={`rounded-full ${isMobile ? "px-[18px] py-[18px]" : "px-[28px] py-[28px]"}`}
                  placeholder={undefined}
                  children={undefined}
                  onClick={moveToTop}
                >
                  <FontAwesomeIcon icon={faArrowUp} size="lg" />
                </IconButton>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
