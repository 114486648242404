import Text from "../../components/design-system/Text";
import Thumbnail from "./form/Thumbnail";
import styled from "styled-components";
import UploadAutoInput from "../../components/design-system/input/UploadAutoInput";
import UploadNormalInput from "../../components/design-system/input/UploadNormalInput";
import { useEffect, useState } from "react";
import CustomCheckBox from "../../components/design-system/CustomCheckBox";
import FillButton from "../../components/design-system/button/FillButton";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { toastFetched } from "../../store/reducer/toastSlice";
import UploadStepper from "../../components/upload/UploadStepper";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { useContext } from "../../util/useContext";
import { useAxios } from "../../lib/api/useAxios";
import { ErrorText, UploadTextColor } from "../../styles/color";
import JoinTermsModal from "../join/modal/JoinTermsModal";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { addComma } from "../../util/number";
import { SwitchButton } from "../../components/design-system/SwitchButton";
import { useMediaQuery } from "react-responsive";
import { shallowEqual } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import {
  thumbnailFlagFetched,
  thumbnailPercentFlagFetched,
  uploadFlagFetched,
} from "../../store/reducer/uploadEndSlice";
import {
  setThumbnail,
  setUploadPayload,
} from "../../store/reducer/uploadSlice";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { thumbnailInfo } from "../../api/upload/upload";

// 사파리 브라우저 확인 함수
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
};

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
`;

const CheckboxBorderWrap = styled(BorderWrap)`
  width: 100%;
`;

export default function UploadDetail({
  status,
  detailData,
  saveFileName,
  fileType,
  originFileName,
  startUploadFlag,
}: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const { post, get } = useAxios();
  const { thumbnail_select, upload_payload, thumbnail } = useAppSelector(
    (state) => state.upload,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [keyword, setKeyword] = useState<string[]>([]);
  const [ownershipPrice, setOwnershipPrice] = useState<any>("");
  const [ticketPrice, setTicketPrice] = useState<any>("");
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [switchData, setSwitchData] = useState(false);
  const [switchAi, setSwitchAi] = useState(false);

  // 체크박스 선택 여부
  const [ownerChecked, setOwnerChecked] = useState(false);
  const [tickeChecked, setTicketChecked] = useState(false);

  const thumbnailStat = useAppSelector(
    (state) => state.uploadEnd.thumbnailStat,
    shallowEqual,
  );

  const thumbnailPercent = useAppSelector(
    (state) => state.uploadEnd.thumbnailPercent,
    shallowEqual,
  );

  const handleNextStep = () => {
    const data = {
      file: upload_payload.file,
      thumbnail: upload_payload.thumbnail,
      thumbnail_select: thumbnail_select,
      content_name: title,
      keyword: keyword,
      // ownershipPrice:
      //   ownerChecked && !switchData ? ownershipPrice.replace(/,/g, "") : 0,
      ticketPrice: !switchData ? ticketPrice?.replace(/,/g, "") : 0,
      // ownerChecked: ownerChecked ? ownerChecked : null,
      // tickeChecked: tickeChecked ? tickeChecked : null,
      camera_info: "cannon",
      description: content ? content : "",
      s: startUploadFlag,
    };

    return navigate("/upload-end", {
      state: {
        data: data,
        detailData: detailData?.data,
        saveFileName: saveFileName,
        fileType: fileType,
        originFileName: originFileName,
        lat: detailData.lat,
        lon: detailData.lon,
      },
      replace: true,
    });
  };

  useEffect(() => {
    if (ownerChecked === false) {
      setOwnershipPrice("");
    }

    if (tickeChecked === false) {
      setTicketPrice("");
    }
  }, [ownerChecked, tickeChecked]);

  function shouldDisableButton({
    keyword,
    title,
    status,
    tickeChecked,
    ticketPrice,
    ownerChecked,
    ownershipPrice,
    switchData,
  }: any) {
    // 기본 조건 검사
    const basicConditions =
      keyword.length >= 5 && title !== "" && status !== "pending";

    // 기본 조건이 충족되지 않는 경우, 버튼을 비활성화
    if (!basicConditions) return true;

    // 두 옵션 중 하나만 선택되고 해당 가격이 10 이상인 경우, 버튼을 활성화
    if (switchData || parseInt(ticketPrice.replace(/,/g, ""), 10) >= 100) {
      return false;
    }

    // 두 옵션 모두 선택된 경우
    // if (tickeChecked && ownerChecked) {
    //   // 두 가격 모두 10 이상이고, 소유권 가격이 티켓 가격보다 큰 경우, 버튼을 활성화
    //   if (
    //     parseInt(ticketPrice.replace(/,/g, ""), 10) >= 100 &&
    //     parseInt(ownershipPrice.replace(/,/g, ""), 10) >
    //       parseInt(ticketPrice.replace(/,/g, ""), 10)
    //   ) {
    //     return false;
    //   }
    // }

    // 그 외의 모든 경우에는 버튼을 비활성화
    return true;
  }

  useEffect(() => {
    if (switchData) {
      setOwnerChecked(false);
      setTicketChecked(false);
    }
  }, [switchData]);

  const inputPriceFormat = (str: any) => {
    const comma = (str: any) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str: any) => {
      return str.replace(/[^\d]+/g, "");
    };
    return comma(uncomma(str));
  };

  const { data } = useQuery({
    queryKey: ["thumbnail-stat"],
    queryFn: () => thumbnailInfo(thumbnailStat, startUploadFlag),
    enabled: thumbnailStat !== "", // 조건에 따라 쿼리 활성화 여부 결정
    refetchInterval: thumbnailStat !== "" ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (data?.data.status === "PROGRESSING") {
      dispatch(thumbnailPercentFlagFetched(data.data.percent));
    } else if (data?.data.status === "COMPLETE") {
      dispatch(thumbnailFlagFetched(""));
      dispatch(thumbnailPercentFlagFetched(data.data.percent));
      dispatch(setThumbnail(data.data.thumbnailList));
      dispatch(setUploadPayload({ thumbnail: data.data.thumbnailList[0] }));
    }
  }, [data]);

  useEffect(() => {
    const lowercasedKeywords = keyword.map((word: string) =>
      word.toLowerCase().trim(),
    );

    if (lowercasedKeywords.includes("ai")) {
      setSwitchAi(true);
    } else {
      setSwitchAi(false);
    }
  }, [keyword]);

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full w-full">
        <FadeMotionDiv>
          <div
            className={`flex flex-col justify-start items-start p-12 ${isMobile ? "max-w-[420px]" : "min-w-[1280px] max-w-[1280px]"}`}
          >
            <div className="flex justify-between items-center w-full">
              <Text className="title mb-5 dark:text-darkText">
                콘텐츠 세부 정보
              </Text>

              {isMobile ? null : <UploadStepper />}
            </div>

            <div className="mt-5 mb-10 flex flex-col gap-4 w-full">
              <BorderWrap className="p-6">
                <UploadNormalInput
                  value={title}
                  setValue={(e: any) => setTitle(e.target.value)}
                  label="제목"
                  placeholder="콘텐츠 제목을 입력해주세요."
                  helperText="제목은 필수 입니다."
                  error={title === ""}
                />
              </BorderWrap>

              <BorderWrap className="p-6">
                <UploadNormalInput
                  value={content}
                  setValue={(e: any) => setContent(e.target.value)}
                  label="설명"
                  placeholder="콘텐츠 설명을 입력해주세요."
                />
              </BorderWrap>

              <BorderWrap className="p-6 ">
                <UploadAutoInput
                  value={keyword}
                  setValue={setKeyword}
                  switchAi={switchAi}
                  setSwitchAi={setSwitchAi}
                />
              </BorderWrap>
            </div>

            <div className="mb-8">
              <Text className="subtitle-3 dark:text-darkText mb-3">썸네일</Text>

              {status === "pending" ? null : thumbnailPercent ? (
                <Text className="subtitle-3 dark:text-darkText mb-3">
                  {thumbnailPercent}%
                </Text>
              ) : thumbnailPercent === null &&
                data?.data.status === "PROGRESSING" ? (
                <Text className="subtitle-3 dark:text-darkText mb-3">10%</Text>
              ) : thumbnailStat === "COMPLETE" &&
                thumbnailPercent === null ? null : null}

              <Thumbnail
                makeStatus={status}
                status={data?.data.status}
                data={state?.res.thumbnailList}
              />
            </div>

            <div className="w-full">
              <div
                className={`flex ${isMobile ? "flex-col" : "items-center"} mb-3`}
              >
                <Text className="subtitle-3 dark:text-darkText w-[103px]">
                  판매 금액 설정
                </Text>
                <div className="w-full flex items-center gap-3 ml-5 ">
                  <SwitchButton
                    id={"free"}
                    value={switchData}
                    onChange={() => setSwitchData(!switchData)}
                  />
                  <Text className="label-1 dark:text-darkText">
                    무료로 판매할래요!
                  </Text>
                </div>
              </div>

              <div className={`flex ${isMobile && "flex-col"} gap-3`}>
                <CheckboxBorderWrap className="p-5 pr-4 flex flex-col gap-2">
                  <div
                    className={`flex ${isMobile ? "flex-col" : "items-center"}  gap-6`}
                  >
                    {/*<CustomCheckBox*/}
                    {/*  labelStyle="dark:text-darkText font-bold w-[74px]"*/}
                    {/*  checked={tickeChecked}*/}
                    {/*  onChange={() => {*/}
                    {/*    if (switchData) {*/}
                    {/*      dispatch(*/}
                    {/*        toastFetched({*/}
                    {/*          show: true,*/}
                    {/*          text: "무료로 판매하기를 해제 해주세요!",*/}
                    {/*          type: "error",*/}
                    {/*        }),*/}
                    {/*      );*/}
                    {/*    } else {*/}
                    {/*      setTicketChecked(!tickeChecked);*/}
                    {/*    }*/}
                    {/*  }}*/}
                    {/*  label={"1년 이용권"}*/}
                    {/*/>*/}

                    {isMobile ? (
                      <div className={"flex"}>
                        <Text className="dark:text-darkText">₩</Text>
                        <input
                          type="text"
                          min={100}
                          placeholder="이용권 금액 입력"
                          className={`outline-none bg-inherit dark:text-darkText  placeholder:text-[14px] disabled:text-gray-400 disabled:bg-gray-100 ${isMobile ? "w-[200px]" : "w-[300px]"} pl-4`}
                          value={ticketPrice}
                          onChange={(e: any) => {
                            const value = inputPriceFormat(e.target.value);
                            const numericValue = parseInt(
                              value.replace(/,/g, ""),
                              10,
                            ); // 천 단위 콤마 제거 후 숫자로 변환

                            if (value === "" || numericValue <= 10000000) {
                              // 빈 문자열이거나 1000만원 이하인지 확인
                              setTicketPrice(value);
                            }
                          }}
                          disabled={switchData}
                        />
                      </div>
                    ) : (
                      <>
                        <Text className="dark:text-darkText">₩</Text>
                        <input
                          type="text"
                          min={100}
                          placeholder="이용권 금액 입력"
                          className={`outline-none bg-inherit dark:text-darkText  placeholder:text-[14px] disabled:text-gray-400 disabled:bg-gray-100 ${isMobile ? "w-[200px]" : "w-[300px]"}`}
                          value={ticketPrice}
                          onChange={(e: any) => {
                            const value = inputPriceFormat(e.target.value);
                            const numericValue = parseInt(
                              value.replace(/,/g, ""),
                              10,
                            ); // 천 단위 콤마 제거 후 숫자로 변환

                            if (value === "" || numericValue <= 10000000) {
                              // 빈 문자열이거나 1000만원 이하인지 확인
                              setTicketPrice(value);
                            }
                          }}
                          disabled={switchData}
                        />
                      </>
                    )}
                  </div>
                </CheckboxBorderWrap>

                {/*<CheckboxBorderWrap className="p-5 pr-4 flex flex-col gap-2">*/}
                {/*  <div*/}
                {/*    className={`flex ${isMobile ? "flex-col" : "items-center"}  gap-6`}*/}
                {/*  >*/}
                {/*    <CustomCheckBox*/}
                {/*      disabled={true}*/}
                {/*      labelStyle="dark:text-darkText"*/}
                {/*      checked={ownerChecked}*/}
                {/*      onChange={() => {*/}
                {/*        if (switchData) {*/}
                {/*          dispatch(*/}
                {/*            toastFetched({*/}
                {/*              show: true,*/}
                {/*              text: "무료로 판매하기를 해제 해주세요!",*/}
                {/*              type: "error",*/}
                {/*            }),*/}
                {/*          );*/}
                {/*        } else {*/}
                {/*          setOwnerChecked(!ownerChecked);*/}
                {/*        }*/}
                {/*      }}*/}
                {/*      label={"소유권"}*/}
                {/*    />*/}

                {/*    {isMobile ? (*/}
                {/*      <div className={"flex"}>*/}
                {/*        <Text className="dark:text-darkText">₩</Text>*/}
                {/*        <input*/}
                {/*          type="text"*/}
                {/*          min={100}*/}
                {/*          placeholder="잠정 중단될 예정이에요"*/}
                {/*          className={`outline-none bg-inherit dark:text-darkText  placeholder:text-[14px] disabled:text-gray-400 disabled:bg-gray-100 ${isMobile ? "w-[200px]" : "w-[300px]"} pl-4`}*/}
                {/*          value={ownershipPrice}*/}
                {/*          onChange={(e: any) => {*/}
                {/*            const value = inputPriceFormat(e.target.value);*/}
                {/*            const numericValue = parseInt(*/}
                {/*              value.replace(/,/g, ""),*/}
                {/*              10,*/}
                {/*            ); // 천 단위 콤마 제거 후 숫자로 변환*/}

                {/*            if (value === "" || numericValue <= 50000000) {*/}
                {/*              // 빈 문자열이거나 1000만원 이하인지 확인*/}
                {/*              setOwnershipPrice(value);*/}
                {/*            }*/}
                {/*          }}*/}
                {/*          disabled={true}*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    ) : (*/}
                {/*      <>*/}
                {/*        <Text className="dark:text-darkText">₩</Text>*/}
                {/*        <input*/}
                {/*          type="text"*/}
                {/*          min={100}*/}
                {/*          placeholder="잠정 중단될 예정이에요"*/}
                {/*          className={`outline-none bg-inherit dark:text-darkText  placeholder:text-[14px] disabled:text-gray-400 disabled:bg-gray-100 ${isMobile ? "w-[200px]" : "w-[300px]"}`}*/}
                {/*          value={ownershipPrice}*/}
                {/*          onChange={(e: any) => {*/}
                {/*            const value = inputPriceFormat(e.target.value);*/}
                {/*            const numericValue = parseInt(*/}
                {/*              value.replace(/,/g, ""),*/}
                {/*              10,*/}
                {/*            ); // 천 단위 콤마 제거 후 숫자로 변환*/}

                {/*            if (value === "" || numericValue <= 50000000) {*/}
                {/*              // 빈 문자열이거나 1000만원 이하인지 확인*/}
                {/*              setOwnershipPrice(value);*/}
                {/*            }*/}
                {/*          }}*/}
                {/*          disabled={true}*/}
                {/*        />*/}
                {/*      </>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</CheckboxBorderWrap>*/}
              </div>
            </div>

            <div className="w-full flex justify-center items-center gap-3 mt-4">
              <SwitchButton
                id={"ai"}
                checked={switchAi}
                onChange={() => setSwitchAi(!switchAi)}
              />
              <Text className="label-1 dark:text-darkText">
                AI로 제작한 콘텐츠 입니다.
              </Text>
            </div>

            {ownerChecked &&
              parseInt(ticketPrice.replace(/,/g, ""), 10) >
                parseInt(ownershipPrice.replace(/,/g, ""), 10) && (
                <div className="w-full flex items-center justify-end mt-3 gap-2">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-[#dc281f]"
                  />
                  <Text fontSize={14} fontWeight={600} color={ErrorText}>
                    판매 금액이 1년 이용권 금액보다 작으면 안돼요!
                  </Text>
                </div>
              )}

            <div className="mt-5">
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                <li>
                  1년 이용권은 1년간 상업/비상업적 용도로 사용할 수 있는
                  이용권이에요.
                </li>
                {/*<li>*/}
                {/*  소유권은 콘텐츠의 모든 권한(소유권)이 구매자에게 승계 됨으로*/}
                {/*  신중한 판매 금액 설정 부탁드립니다.*/}
                {/*</li>*/}
                <li>모든 콘텐츠는 판매시, 환불이 불가능 합니다.</li>
                <li>
                  유저의 실수로 인한 사고 발생시, 스탠바이는 책임지지 않습니다.
                  이용약관을 꼭 확인해주세요.
                </li>
              </ul>
              <Text
                fontSize={12}
                fontWeight={600}
                color={"#A7A7A7"}
                className="cursor-pointer underline underline-offset-4"
                onClick={() => setInfoModal({ show: true })}
              >
                *이용약관 확인하기
              </Text>
            </div>

            {/*<div className="mt-10">*/}
            {/*  <img src="/img/standbuy/background/upload-info.svg" />*/}
            {/*</div>*/}

            <div className="flex flex-col items-center justify-center w-full mt-20">
              <Text
                fontSize={14}
                fontWeight={600}
                color={UploadTextColor}
                className="mb-3"
              >
                *콘텐츠가 판매되면 거래취소가 불가함으로 신중하게 업로드해
                주세요.
              </Text>
              <FillButton
                onClick={handleNextStep}
                text={"다음"}
                className="w-[360px]"
                size="lg"
                disabled={
                  thumbnail?.length === 0
                    ? true
                    : shouldDisableButton({
                        keyword,
                        title,
                        status,
                        tickeChecked,
                        ticketPrice,
                        ownerChecked,
                        ownershipPrice,
                        switchData,
                      })
                }
              />
            </div>
          </div>
        </FadeMotionDiv>

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>

      {infoModal.show && (
        <JoinTermsModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}
