import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import NoticeTable from "./card/NoticeTable";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import GoogleAd from "../../components/ads/GoogleAd";

export default function NoticePage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { get } = useAxios();

  const { data } = useQuery({
    queryKey: ["notice"],
    queryFn: async () => await get(`${API_DEFAULT}/user/notice/get`),
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className={`${isMobile && "px-4"}`}>
            <div className="flex justify-between items-center mb-[30px]">
              <Text className="title dark:text-darkText">공지사항</Text>
            </div>
            <NoticeTable data={data?.data} />
          </div>
        </div>
      </motion.div>

      <div className="w-full sm:max-w-[728px] mx-auto my-5">
        <GoogleAd />
      </div>

      {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />}
    </div>
  );
}
