//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Chip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function SearchChip({ state, chips, setChips, setGroupKey }: any) {
  const navigate = useNavigate();
  const searchString = sessionStorage.getItem("search");
  const keywordsString = sessionStorage.getItem("keywords");
  const keywordsArray = JSON.parse(keywordsString);

  const priceString: any = sessionStorage.getItem("price");
  const priceArray: any[] = JSON.parse(priceString || "[]");

  const viewString: any = sessionStorage.getItem("view");
  const viewArray: any[] = JSON.parse(viewString || "[]");

  const handleDelete = (index: number) => {
    setChips(chips.filter((_, chipIndex) => chipIndex !== index));

    const checkedItemsString = chips.filter(
      (_, chipIndex) => chipIndex !== index
    );

    sessionStorage.removeItem("search");

    navigate(`/feed/filter`, {
      state: {
        keyword: keywordsArray,
        price: priceArray,
        search: "",
        view: viewArray,
      },
      replace: true,
    });
  };

  useEffect(() => {
    setChips(state);
  }, [state]);

  return (
    <>
      {state?.map((item: any, index: any) => (
        <Chip key={index} value={item} onClose={() => handleDelete(index)} />
      ))}
    </>
  );
}
