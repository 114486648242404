//@ts-nocheck
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { setToggleFetched } from "../../../store/reducer/themeSlice";

export default function FilterReload({ toggle }: any) {
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <div onClick={() => dispatch(setToggleFetched(!toggle))}>
      <IconButton
        variant="outlined"
        className="rounded-full focus:ring-transparent"
        size="sm"
      >
        <FontAwesomeIcon
          icon={faSliders}
          className={`w-[14px] h-[14px] ${
            themeData === "light" || themeData === "" ? "" : "text-darkText"
          }`}
        />
      </IconButton>
    </div>
  );
}
