import { createSlice } from "@reduxjs/toolkit";

const initialBoundsState = {
  ne: { lat: 0, lng: 0 },
  sw: { lat: 0, lng: 0 },
  zoom: 9,
  searchZoom: 17,
};

export const mapBoundSlice = createSlice({
  name: "bounds",
  initialState: initialBoundsState,
  reducers: {
    boundsFetched: (state, action) => {
      state.ne = action.payload.ne;
      state.sw = action.payload.sw;
    },
    zoomFetched: (state, action) => {
      state.zoom = action.payload.zoom;
    },
    searchZoomFetched: (state, action) => {
      state.searchZoom = action.payload.searchZoom;
    },
  },
});

export const { boundsFetched, zoomFetched, searchZoomFetched } =
  mapBoundSlice.actions;

export default mapBoundSlice.reducer;
