//@ts-noCheck
import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { setSearchStudioList } from "../../store/reducer/mainStock";
import StockListCard from "../feed/stock-list/StockListCard";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { ChipDelete } from "../../components/feed/filter/ChipDelete";
import FilterBar from "../../components/feed/filter/FilterBar";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import MobileTopSection from "../../components/common/MobileTopSection";
import SlideBanner from "../../components/common/SlideBanner";
import FilterContainer from "../../components/feed/filter/box/FilterContainer";
import { SearchChip } from "../../components/feed/filter/SearchChip";
import { PriceChip } from "../../components/feed/filter/PriceChip";
import { ViewChip } from "../../components/feed/filter/viewChip";

export default function FeedFilterPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const { state } = useContext();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { filterImage } = useAppSelector((state) => state.assets);
  const [chips, setChips] = useState(() => {
    if (Array.isArray(state.keyword)) {
      return state.keyword;
    } else if (state.keyword) {
      return [state.keyword];
    } else {
      return [];
    }
  });
  const [searchChips, setSearchChips] = useState<any>();
  const [priceChips, setPriceChips] = useState<any>();
  const [viewChips, setViewChips] = useState<any>();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [items, setItems] = useState<any[]>([]);
  const [toggle, setToggle] = useState(true);
  const [searchArray, setSearchArray] = useState<any>();

  const priceString: any = sessionStorage.getItem("price");
  const priceArray: any[] = JSON.parse(priceString || "[]");

  const viewString: any = sessionStorage.getItem("view");
  const viewArray: any[] = JSON.parse(viewString || "[]");

  // 데이터를 가져오는 함수
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await instance.post(`${API_DEFAULT}/contents/search/new`, {
      text: state.search || null,
      grade: chips?.includes("DIRECTOR") ? "ROLE_DIRECTOR" : null,
      classification: chips?.includes("IMAGE")
        ? "IMAGE"
        : chips?.includes("VIDEO")
          ? "VIDEO"
          : null,
      priceFrom: state?.price?.[0] ?? priceArray?.[0] ?? null,
      priceTo: state?.price?.[1] ?? priceArray?.[1] ?? null,
      resolutionFrom: state?.view?.[0] ?? viewArray?.[0] ?? null,
      resolutionTo: state?.view?.[1] ?? viewArray?.[1] ?? null,
      keywords:
        chips && chips.length > 0
          ? chips.includes("IMAGE") ||
            chips.includes("VIDEO") ||
            chips.includes("구독") ||
            chips.includes("DIRECTOR")
            ? (() => {
                const filtered = chips.filter(
                  (item: any) =>
                    item !== "IMAGE" &&
                    item !== "VIDEO" &&
                    item !== "구독" &&
                    item !== "DIRECTOR"
                );
                return filtered.length > 0 ? filtered : null;
              })()
            : chips
          : null,
      sub: !!chips?.includes("구독"),
      page: nextGroupKey,
      size: count,
    });
    if (res?.data.success) {
      nextItems = res.data.data;
      if (nextItems.length === 0) {
        return [];
      }
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  // chips나 state가 변경될 때 items와 groupKey를 초기화
  useEffect(() => {
    setItems([]);
    setGroupKey(null);
  }, [chips, searchChips]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // state가 없을 경우 /feed로 리디렉션
  useEffect(() => {
    if (
      (!state?.keyword || state.keyword.length < 1) &&
      (!state?.search || state.search.length < 1) &&
      (!state?.price || state.price.length < 1) &&
      (!state?.view || state.view.length < 1)
    ) {
      navigate("/feed");
    }
  }, [chips, state, navigate]);

  // 검색어 배열로 설정
  useEffect(() => {
    setSearchArray(Array(state?.search));
  }, [state?.search]);

  return (
    <div
      className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10`}
    >
      <div className="my-[50px] mb-[30px]">
        {isMobile ? <MobileTopSection /> : <SlideBanner />}
      </div>
      <div className="flex items-center gap-4 mb-[30px]">
        <Text className="title dark:text-darkText">
          <Text span color="#134B70" className="title mr-[10px]">
            {state?.search ? "검색어 : " + state?.search + ", " : null}
            {priceArray?.length > 1
              ? "가격 : " + priceArray[0] + " ~ " + priceArray[1] + ", "
              : null}
            {viewArray?.length > 1
              ? "해상도 : " + viewArray[0] + " ~ " + viewArray[1] + ", "
              : null}
            {chips?.length >= 1 &&
              chips?.map((item: any, index: any) => (
                <Text
                  key={index}
                  span
                  color="#134B70"
                  className="title mr-[10px]"
                >
                  {chips.length > 1 ? item + "," : item}
                </Text>
              ))}
          </Text>
          검색 결과
        </Text>
      </div>

      <div className="mb-[30px] flex gap-5 overflow-auto">
        {isMobile && <FilterBar items={items} />}
        {state.search && (
          <SearchChip
            state={searchArray}
            chips={searchChips}
            setChips={setSearchChips}
          />
        )}
        {state?.price && (
          <PriceChip
            state={state.price}
            chips={priceChips}
            setChips={setPriceChips}
          />
        )}
        {state?.price && (
          <ViewChip
            state={state.view}
            chips={viewChips}
            setChips={setViewChips}
          />
        )}
        <ChipDelete
          state={state.keyword}
          chips={chips}
          setChips={setChips}
          setGroupKey={setGroupKey}
        />
      </div>

      <div className={`mt-[30px] ${!isMobile && "flex"}`}>
        {!isMobile && <FilterContainer toggle={toggle} setToggle={setToggle} />}

        <JustifiedInfiniteGrid
          // useResizeObserver={true}
          className="transition-all duration-300 ease-in-out"
          style={{
            width: toggle && !isMobile ? "calc(100% - 220px)" : "100%",
          }}
          placeholder={
            <div className="placeholder">추가 데이터를 불러오는 중...</div>
          }
          stretch={true}
          passUnstretchRow={true}
          sizeRange={isMobile ? [228, 228] : [440, 540]}
          // stretchRange={[144, 320]}
          {...(isMobile && { columnRange: [0, 2] })}
          threshold={3000}
          gap={8}
          onRequestAppend={async (e) => {
            const nextGroupKey = (+e.groupKey! || 0) + 1;

            // 중복 호출 방지
            if (groupKey !== null && nextGroupKey <= groupKey) {
              return;
            }

            setGroupKey(nextGroupKey);
            e.wait();

            setTimeout(async () => {
              const add = await getItems(nextGroupKey, 30);

              if (add.length === 0) {
                e.ready();
                return true;
              }

              dispatch(setSearchStudioList({ data: add, reset: false }));
              setItems((prevItems) => [...prevItems, ...add]);
              e.ready();
            }, 1);
          }}
        >
          {items.length < 1 ? (
            <div className="flex flex-col items-center justify-center">
              <Text className="subtitle-2 dark:text-darkText">
                조회된 피드가 없습니다.
              </Text>
            </div>
          ) : (
            items.map((item: any, index: any) => (
              <StockListCard
                data-grid-groupkey={item.groupKey}
                key={index}
                index={index}
                item={item}
                onClick={undefined}
              />
            ))
          )}
        </JustifiedInfiniteGrid>
      </div>

      <div
        className={`fixed bottom-10 ${
          isMobile ? "left-5" : "right-10"
        } z-[9999]`}
      >
        <div className="flex flex-col justify-center items-center gap-4">
          <FeedNav />

          {!isMobile && (
            <IconButton
              className="rounded-full px-[28px] py-[28px]"
              size="lg"
              onClick={moveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
