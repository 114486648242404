import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import CustomTooltip from "../../../components/design-system/CustomTooltip";
import FillButton from "../../../components/design-system/button/FillButton";
import { LabelInputReset } from "../../../components/design-system/input/LabelInputReset";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch } from "../../../store/store";
import { API_DEFAULT } from "../../../api/api";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { BankSelect } from "../../../components/design-system/BankSelect";
import { Radio } from "@material-tailwind/react";
import { addBankInfo } from "../../../api/exchange/exchange";

export default function BankAddModal({ open, onClose }: any) {
  const queryClient = useQueryClient();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState<any>();
  const [step, setStep] = useState({ step: 1 });
  const [selectedBank, setSelectedBank] = useState("");
  const [value, setValue] = useState<any>({
    person: false,
    company: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (type: any) => {
    setValue({
      person: type === "person",
      company: type === "company",
    });
  };

  const { mutate, status } = useMutation({
    mutationFn: addBankInfo,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["me-data"] });
      if (data?.success) {
        window.location.reload();
        onClose();
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  useEffect(() => {
    //@ts-ignore
    if (status === "pending") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [status]);

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex items-center gap-2">
        <Text
          fontSize={18}
          fontWeight={700}
          color="#242424"
          className="dark:text-darkText"
        >
          정산 계좌 정보 등록
        </Text>
        <CustomTooltip title="정산 기능을 사용하려면 정산에 필요한 정보를 입력해야 합니다." />
      </div>

      <div className="mt-4 flex flex-col gap-12">
        <div className="flex flex-col mt-4">
          <Text
            fontSize={14}
            fontWeight={500}
            color="#4a5568"
            className="dark:text-darkText"
          >
            고객 구분
          </Text>
          <div className="flex items-center gap-4">
            <Radio
              value={value.person}
              onChange={() => handleChange("person")}
              name="type"
              label={
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color="#555"
                  className="dark:text-darkText"
                >
                  개인
                </Text>
              }
              crossOrigin={undefined}
              ripple={true}
              className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
              containerProps={{
                className: "m-0",
              }}
            />
            <Radio
              value={value.company}
              onChange={() => handleChange("company")}
              name="type"
              label={
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color="#555"
                  className="dark:text-darkText"
                >
                  사업자
                </Text>
              }
              ripple={true}
              crossOrigin={undefined}
              className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
            />
          </div>
        </div>

        <BankSelect
          selectedBank={selectedBank}
          setSelectedBank={setSelectedBank}
        />

        <div className="mt-3">
          <LabelInputReset
            label="계좌번호"
            value={accountNumber}
            setValue={setAccountNumber}
            placeholder={"정산받을 계좌번호를 입력해주세요."}
            maxLength={30}
          />

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "6px",
            }}
          >
            <li>본인 명의의 계좌만 가능합니다.</li>
            <li>계좌번호는 숫자만 입력해주세요!</li>
          </ul>
        </div>
      </div>

      <div className="mt-4 flex flex-col items-center">
        <FillButton
          text="등록하기"
          className="w-full mt-[30px] flex justify-center"
          size="lg"
          onClick={() => {
            const cleanedAccountNumber = String(accountNumber)?.replace(
              "-",
              ""
            );
            mutate({
              bankCode: selectedBank,
              accountNumber: cleanedAccountNumber,
              acctGb: value.person ? 1 : 2,
            });
          }}
          loading={loading}
          disabled={loading}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
