import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactHlsPlayer from "react-hls-player";
import { motion } from "framer-motion";
import Text from "../../../components/design-system/Text";
import FeedInfoModal from "../modal/FeedInfoModal";
import { Avatar } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import StockText from "../../../components/design-system/StockText";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { Tooltip, Typography } from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import CartEndModal from "../../cart/modal/CartEndModal";
import FeedDeleteModal from "../modal/FeedDeleteModal";
import { useContext } from "../../../util/useContext";
import { URL_HOST } from "../../../api/api";

declare global {
  interface Window {
    navigateToPage: (url: string, options?: any) => void;
  }
}

const Content = styled.div<any>`
  position: relative;
  .wrap {
    overflow: auto;
  }
  .direct {
    position: absolute;
    top: 0;
    right: 0;
    width: 21px;
    height: 22px;
  }
  /* 마우스오버시나오는정보 */
  .status {
    border-radius: 12px;
    position: absolute;
    opacity: 0; /* 기본적으로 투명 */
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; /* 비디오 전체를 덮도록 수정 */
    padding: 18px 25px;
    background: linear-gradient(
      to bottom,
      rgba(249, 249, 249, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  button.item {
    &:hover {
      .status {
        opacity: 1;
      }
    }
  }
  img {
    display: inline-block;
    /* max-width: 236px; */
    /* max-height: 400px;  */

    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    vertical-align: top;
    object-fit: cover;
  }
  .video {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .video-wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
  }
  h1 {
    display: block;
    margin-top: 15px;
    margin-left: 20px;
    color: #515151;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
`;

export default function StockListCard({ item, index, onClick }: Props) {
  const playerRef = useRef() as any;
  const { me } = useContext();
  const location = useLocation();
  const [isHover, setIsHover] = useState(false);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const [endModal, setEndModal] = useState<any>({
    show: false,
    data: "",
  });
  const { versionData } = useAppSelector((state) => state.mobile);

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <>
      <Content>
        {item.grade === "ROLE_DIRECTOR" && (
          <div className={"w-[30px] absolute top-2 right-2 z-[998]"}>
            <Tooltip
              content={<div>Director 마크 : 전문가들의 콘텐츠에요😀</div>}
            >
              <img src={"/img/standbuy/icon/dr-badge.svg"} alt="director" />
            </Tooltip>
          </div>
        )}

        <div key={index}>
          <button
            onMouseEnter={!isMobile ? () => setIsHover(true) : undefined}
            onMouseLeave={!isMobile ? () => setIsHover(false) : undefined}
            className="item"
            onClick={() => {
              if (item?.status === "SANCTION") {
                setInfoModal({ show: true, data: item });
                if (me?.memberId === item?.memberId) {
                  setEndModal({ show: true, data: item });
                }
              } else {
                if (
                  location.pathname.includes("/feed") ||
                  location.pathname.includes("/feed/filter") ||
                  location.pathname.includes("/location-feed") ||
                  location.pathname.includes("studio")
                ) {
                  if (versionData) {
                    navigate(`${location.pathname}?id=${item?.contentId}`);
                    setInfoModal({ show: true, data: item });
                  } else {
                    window.name = JSON.stringify({
                      contentId: item?.contentId,
                    });
                    window.open(
                      `${URL_HOST}feed-info`,
                      "feed",
                      `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
                    );
                  }
                }
              }
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <div className="flex justify-center items-center relative">
                <div className={"relative"}>
                  <img
                    className={`img rounded-xl ${item?.status === "SANCTION" ? "blur-lg" : ""}`}
                    src={item?.thumbnailPath}
                    alt="index"
                    data-grid-maintained-target="true"
                  />
                </div>

                <aside className="status truncate">
                  <div className="h-full flex flex-col justify-end items-start gap-4">
                    <div className="flex items-center gap-3">
                      <div className="w-[30px] h-[30px]">
                        <Avatar
                          size="sm"
                          variant="circular"
                          alt="profile img"
                          src={
                            item?.memberProfileImage
                              ? item?.memberProfileImage
                              : "/img/standbuy/icon/no-profile.svg"
                          }
                          placeholder={undefined}
                          className="border border-[#134B70] ring-1 ring-[#134B70] p-0.5 w-[30px] h-[30px] cursor-pointer z-[100]"
                        />
                      </div>

                      <StockText fontSize={15} fontWeight={600} color="#fff">
                        {item?.memberNickname}
                      </StockText>
                    </div>

                    <div className="w-full flex items-center justify-between">
                      <StockText
                        fontSize={18}
                        fontWeight={700}
                        color="#fff"
                        className="truncate"
                      >
                        {item?.contentName}
                      </StockText>

                      <div className="flex items-center gap-1">
                        <FontAwesomeIcon
                          fontSize={20}
                          icon={faHeart}
                          className={`text-red-500`}
                        />
                        <Text fontSize={15} fontWeight={700} color="#ef4444">
                          {item?.likesCount}
                        </Text>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </motion.div>
            {isHover && (
              <div className="video-wrap">
                {item?.classification === "VIDEO" &&
                  item?.status === "ENABLE" && (
                    <ReactHlsPlayer
                      className="video"
                      autoPlay={true}
                      controls={false}
                      muted={true}
                      loop={true}
                      playerRef={playerRef}
                      src={item?.previewPath}
                      hlsConfig={{
                        maxLoadingDelay: 4,
                        minAutoBitrate: 0,
                        lowLatencyMode: true,
                      }}
                    />
                  )}
              </div>
            )}
          </button>
        </div>
      </Content>

      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}

      {endModal.show && (
        <FeedDeleteModal
          open={endModal.show}
          onClose={() => setEndModal({ show: false })}
          data={endModal.data}
        />
      )}
    </>
  );
}

type Props = {
  item: any;
  index: number;
  width?: string;
  height?: string;
  onClick?: (item: any) => void;
};
